import React, { useContext, useCallback, useMemo } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { ControlOutlined } from '@ant-design/icons';
import SessionContext from '@store/SessionContext/SessionContext';
import ActionTypes from '@src/utils/deviceActions';
import roles from '@utils/rolesConstants';

const DeviceActionsMenu = ({
  button,
  onActionClick,
  doubleActions,
  showContracted,
  stock,
  disabled,
  isMoT = false,
}) => {
  const { t } = useTranslation('device-actions');
  const {
    customer: { isCustomerFinanciallyBlocked },
    userRoles,
  } = useContext(SessionContext);

  const allActions = useMemo(() => {
    const actionsDefault = [
      'NETWORK_RESET',
      'SIMCARD_RESET',
      'TRANSFER',
      'NICKNAME_CHANGE',
      'PLASTIC_EXCHANGE',
      'MSISDN_EXCHANGE',
      'MOT_OPERATOR_CHANGE',
      'SMS_SEND',
      'HLR_REPROG',
    ];

    if (isMoT && !isCustomerFinanciallyBlocked && stock) {
      actionsDefault.push('MOT_DEACTIVATION');
    }
    if (showContracted !== 'true') {
      actionsDefault.push(
        'MANUAL_ACTIVATION',
        'FORCE_REACTIVATION',
        'ADD_CREDIT_TO_SIMCARD',
      );
    }

    return actionsDefault;
  }, [showContracted, isMoT, isCustomerFinanciallyBlocked, stock]);

  const checkRole = useCallback(
    role => userRoles?.portal?.includes(role),
    [userRoles],
  );

  const handleLockUnlockRoles = useCallback(
    (type, bothRole, lockRole, unlockRole) => {
      switch (type) {
        case 'block':
          return lockRole;
        case 'unlock':
          return unlockRole;
        case 'both':
        default:
          return bothRole;
      }
    },
    [],
  );

  const actionsRoles = useMemo(() => {
    const activeRoles = [...allActions];
    console.log(allActions, 'allActions');

    doubleActions?.forEach(
      ({ selectedDevices, actionRole, actionBlock, actionUnlock }) => {
        activeRoles.push(
          handleLockUnlockRoles(
            selectedDevices?.type,
            ...[actionRole, actionBlock, actionUnlock],
          ),
        );
      },
    );

    return activeRoles;
  }, [handleLockUnlockRoles, doubleActions, allActions]);

  const handleDoubleActionRole = useCallback(
    role => {
      let doubleActionRole;
      doubleActions?.forEach(({ actionRole, actionBlock, actionUnlock }) => {
        if ([actionRole, actionBlock, actionUnlock].includes(role)) {
          doubleActionRole = actionRole;
        }
      });

      return doubleActionRole ?? role;
    },
    [doubleActions],
  );

  const menu = useMemo(
    () => (
      <Menu
        inlineIndent={8}
        onClick={({ key, keyPath, item, domEvent }) =>
          onActionClick && onActionClick({ key, keyPath, item, domEvent })
        }
      >
        {actionsRoles.map(role => {
          const hasRole = checkRole(roles[handleDoubleActionRole(role)]);

          if (!hasRole) {
            return;
          }
          console.log(role, t(`labels.${ActionTypes[role]}`));
          return (
            <Menu.Item key={ActionTypes[role]}>
              {t(`labels.${ActionTypes[role]}`)}
            </Menu.Item>
          );
        })}
      </Menu>
    ),
    [actionsRoles, checkRole, onActionClick, t, handleDoubleActionRole],
  );

  const menuIsEmpty = useMemo(() => {
    // Filter 'child' = 'undefined'
    const menuChildren = menu.props.children.filter(child => child);
    return menuChildren.length === 0;
  }, [menu]);

  if (menuIsEmpty) {
    return <></>;
  }

  return (
    <Dropdown
      overlay={menu}
      overlayClassName={'overlay-menu'}
      disabled={disabled}
    >
      {button || <Button icon={<ControlOutlined />} type={'default'} />}
    </Dropdown>
  );
};

export default DeviceActionsMenu;
