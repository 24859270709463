import React, { useMemo } from 'react';
import Stat from '@components/Stat/Stat';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, Space } from 'antd';
import './styles.less';

const LoRaConsumptionCard = ({ data = {}, type = 'downlink' }) => {
  const { t } = useTranslation('general');
  const isDownlink = type === 'downlink';

  const consumptionData = useMemo(
    () => ({
      nuFranquiaTotalLora: `${data?.nuFranquiaTotal} ` || 0,
      nuConsumoFranquiaLora: `${data?.nuConsumoFranquia} ` || 0,
      nuConsumoExcedenteLora: `${data?.nuConsumoExcedente} ` || 0,
      nuConsumoTotalLora: `${data?.nuConsumoTotal} ` || 0,
    }),
    [data],
  );

  return (
    <Card
      className="consumption-card"
      title={`${t(`components.consumption-card.consumption`)} LoraWAN ${
        isDownlink ? 'Downlink' : 'Uplink'
      } `}
    >
      <Row gutter={[16, 16]}>
        {Object.entries(consumptionData).map(([key, value]) => (
          <Col key={key} lg={12} md={12} xl={6} xs={24}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Stat
                className={`consumption-stat ${key}`}
                title={
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p>{t(`components.consumption-card.${key}`)}</p>
                    </div>
                  </>
                }
                value={value}
                loading={!value}
              />
            </Space>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default LoRaConsumptionCard;
